<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'SatManagement', query: { type: type } }">
            Practices
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <TestResultsTable
      :type="type"
      :results="results"
      role="admin"
    ></TestResultsTable>
    <div class="text-center" style="margin:15px 0">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :page-count="6"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat";
import TestResultsTable from "@/views/satTestResultsPage/components/TestResultsTable";
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "SAT TestResults - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb,
    TestResultsTable
  },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      search: {
        user_keyword: null,
        test_title: null
      },
      exam: null,
      active: null,
      results: null,
      resultsTotal: 0,
      pageSize: 0,
      isAdmin: null
    };
  },
  computed: {
    examId() {
      return this.$route.params.examId;
    },
    myTitle() {
      return this.results ? this.getCompanyExamTitle(this.results[0].exam.title) : "";
    },
    page() {
      let page = 1;
      if (this.$route.query.page) {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    type() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      } else {
        return "singlePractice";
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.query.user_keyword) {
      this.search.user_keyword = this.$route.query.user_keyword;
    }
    if (this.$route.query.test_title) {
      this.search.test_title = this.$route.query.test_title;
    }
    this.getResults();
  },

  methods: {
    // async getResults1() {
    //   const results = await SAT.getResultsByExam({
    //     exam_id: this.examId,
    //     page: this.page
    //   });
    //   this.exam = results.exam;
    //   this.results = results.user_exams.data;
    //   this.pageSize = results.user_exams.per_page;
    //   this.resultsTotal = results.user_exams.total;
    // },
    async getResults() {
      // console.log(this.isRoleAdmin());
      let results = null;
      if (this.type === "composePractices") {
        results = await SAT.getAdminComposePracticeUserExam({
          exam_id: this.examId,
          page: this.page
        });
      } else if (this.type === "practice") {
        results = await SAT.getAdminPracticeUserExam({
          exam_id: this.examId,
          page: this.page,
          practice_type: null
        });
      } else if (this.type === "drill") {
        results = await SAT.getAdminPracticeUserExam({
          exam_id: this.examId,
          page: this.page,
          practice_type: "drill"
        });
      }
      this.results = results.user_exams.data;
      this.pageSize = results.user_exams.per_page;
      this.resultsTotal = results.user_exams.total;
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    searchPage() {
      this.routerPush({
        name: "SATResults",
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          user_keyword: this.search.user_keyword,
          test_title: this.search.test_title
        }
      });
    }
  }
};
</script>

<style scoped>
.box .container {
  padding-top: 0;
}
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
.linkInTab {
  color: inherit !important;
  display: block;
}

.linkInTab::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}

.linkInTab:hover {
  text-decoration: none;
}
.menu-item {
  height: 40px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .box .container {
    padding: 0 15px 20px 15px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sat_left {
    margin: 0;
    margin-top: 10px;
  }
  .sat_left > p {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
